<template>
  <div class="analytics-page">
    <v-row justify="center">
      <v-col md="6">
        <div class="text-center subtitle-2 font-weight-medium ma-3 mb-n1">
          <p>Analytics data is updated every 1 hour.</p>
        </div>

        <!-- INSTITUTION ANALYTICS -->
        <v-slide-y-transition mode="out-in">
          <v-card
            outlined
            class="mx-2 my-3 px-2 py-3 pb-4"
            v-if="showInstitutionAnalytics"
          >
            <v-row justify="center" dense class="mx-1 mt-2">
              <v-col cols="3" class="text-center ml-n8">
                <p class="body-2 font-weight-bold ma-0">Admin</p>
                <h1 class="primary--text">
                  <animated-counter
                    :number="institutionAnalytics.total_admins"
                  />
                </h1>
              </v-col>

              <v-col cols="4" class="text-center mx-1">
                <p class="body-2 font-weight-bold ma-0">Faculty</p>
                <h1 class="primary--text">
                  <animated-counter
                    :number="institutionAnalytics.total_only_faculty"
                  />
                </h1>
              </v-col>

              <v-col cols="3" class="text-center">
                <p class="body-2 font-weight-bold ma-0">Students</p>
                <h1 class="primary--text text-no-wrap">
                  <animated-counter
                    :number="institutionAnalytics.total_students"
                  />
                </h1>
              </v-col>
            </v-row>

            <v-row justify="center" dense class="mx-1 mt-2">
              <v-col cols="3" class="text-center mx-4 ml-n5">
                <p class="body-2 font-weight-bold ma-0 text-no-wrap">
                  Classrooms
                </p>
                <h1 class="primary--text">
                  <animated-counter
                    :number="institutionAnalytics.total_rooms"
                  />
                </h1>
              </v-col>

              <v-col cols="4" class="text-center mx-4">
                <p class="body-2 font-weight-bold ma-0 text-no-wrap">
                  Disabled Users
                </p>
                <h1 class="primary--text align-center ml-2">
                  <animated-counter
                    :number="institutionAnalytics.disabled_users"
                  />
                </h1>
              </v-col>
            </v-row>

            <!-- <v-row justify="center" dense class="align-center mx-1 mt-2">
              <p class="body-2 font-weight-bold ma-0">App opened</p>
              <h1 class="primary--text mx-2 text-no-wrap">
                <animated-counter
                  :number="institutionAnalytics.times_app_opened"
                />
              </h1>
              <p class="body-2 font-weight-bold ma-0">times today!</p>
            </v-row> -->
          </v-card>
        </v-slide-y-transition>

        <v-divider class="my-4"></v-divider>
        <!-- FEATURE ANALYTICS -->
        <v-slide-y-transition mode="out-in">
          <div v-if="showFeaturesAnalytics">
            <!-- Messages -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="messages"
                  width="50px"
                  height="40px"
                />
                <h3>Messages</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_alerts" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Messages</p>
                </v-col>

                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_alerts" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Messages</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Notice Board -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="notice"
                  width="50px"
                  height="40px"
                />
                <h3>Notice Board</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_notices" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Notices</p>
                </v-col>

                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_notices" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Notices</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Homework -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="homework"
                  width="50px"
                  height="40px"
                />
                <h3>Homework</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_homework" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Homework</p>
                </v-col>

                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_homework" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Homework</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Video Lectures -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="video_lectures"
                  width="50px"
                  height="40px"
                />
                <h3>Video Lectures</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_video_lectures" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Video Lectures</p>
                </v-col>

                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_video_lectures" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Video Lectures</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Online Class  -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="live-lectures"
                  width="50px"
                  height="40px"
                />
                <h3>Online Class</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_video_conferences" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Online Classes</p>
                </v-col>

                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Future</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.future_video_conferences" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Online Classes</p>
                </v-col>

                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_video_conferences" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Online Classes</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Tests -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="tests"
                  width="50px"
                  height="40px"
                />
                <h3>Tests</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="2" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_tests" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Tests</p>
                </v-col>

                <v-col cols="3" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Future</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.future_tests" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Tests</p>
                </v-col>

                <v-col cols="2" class="text-center mx-3 ma-0">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_tests" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Tests</p>
                </v-col>
              </v-row>

              <v-row justify="center" dense class="mx-2 mt-2">
                <v-col cols="4" class="text-center">
                  <p class="body-2 font-weight-bold mx-2 ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_test_submissions" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0 ml-n3 text-no-wrap">
                    Test Submissions
                  </p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Attendance -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="attendance"
                  width="50px"
                  height="40px"
                />
                <h3>Attendance</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_attendance" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0 text-no-wrap">
                    Attendance
                  </p>
                </v-col>

                <!-- <v-col cols="4" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_avg_attendance" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Avg Attendance</p>
                </v-col> -->

                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_attendance" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0 text-no-wrap">
                    Attendance
                  </p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Gallery -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="gallery"
                  width="50px"
                  height="40px"
                />
                <h3>Gallery</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_images" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Images</p>
                </v-col>

                <v-col cols="4" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_videos" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Videos</p>
                </v-col>

                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_gallery" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Posts</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Magazine -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="magazine"
                  width="50px"
                  height="40px"
                />
                <h3>Magazine</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_magazines" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Magazines</p>
                </v-col>

                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_magazines" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Magazines</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Events -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="events"
                  width="50px"
                  height="40px"
                />
                <h3>Events</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_event" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Events</p>
                </v-col>

                <v-col cols="4" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Future</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.future_events" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Events</p>
                </v-col>

                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">All</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.all_events" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Events</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Holidays -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="holidays"
                  width="50px"
                  height="40px"
                />
                <h3>Holidays</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">This Month</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.this_month_holidays" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Holidays</p>
                </v-col>

                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_holidays" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Holidays</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Ask -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="ask"
                  width="50px"
                  height="40px"
                />
                <h3>Ask</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-0 mt-2">
                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_questions" />
                  </h1>
                  <p class="subtitle-2 font-weight-bold ma-0 text-no-wrap">
                    Questions
                  </p>
                </v-col>

                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_answers" />
                  </h1>
                  <p class="subtitle-2 font-weight-bold ma-0">Answers</p>
                </v-col>

                <v-col cols="3" class="text-center mx-2">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_questions" />
                  </h1>
                  <p class="subtitle-2 font-weight-bold ma-0 text-no-wrap">
                    Questions
                  </p>
                </v-col>
              </v-row>

              <v-row justify="center" dense class="mx-0 mt-2">
                <v-col cols="4" class="text-center">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_answers" />
                  </h1>
                  <p class="subtitle-2 font-weight-bold ma-0">Answers</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Feedback -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="feedback"
                  width="50px"
                  height="40px"
                />
                <h3>Feedback</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_feedback" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Feedback</p>
                </v-col>

                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_feedback" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Feedback</p>
                </v-col>
              </v-row>
            </v-card>

            <!-- Downloads (only on Mobile App)  -->
            <v-card
              v-if="showDownloads"
              outlined
              class="mx-2 my-3 px-2 py-3 pb-4"
            >
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="downloads"
                  width="50px"
                  height="40px"
                />
                <h3>Downloads</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="4" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter
                      :number="institutionAnalytics.downloads"
                    />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Downloads</p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Mixins from "@utils/mixins";
import FeatureSvg from "@assets/images/FeatureSvg.vue";
import AnimatedCounter from "@components/ui/AnimatedCounter";

export default {
  name: "Analytics",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    FeatureSvg,
    AnimatedCounter,
  },

  data() {
    return {
      featuresAnalytics: {},
      institutionAnalytics: {},
      showInstitutionAnalytics: false,
      showFeaturesAnalytics: false,
      showDownloads: false,
    };
  },

  methods: {
    async getFeaturesAnalytics() {
      var url = this.endpoints.featuresAnalyticsViewSet;
      console.log(url);
      this.featuresAnalytics = await this.api.call(this.essentials, url);
    },

    async getInstitutionAnalytics() {
      var url = this.endpoints.institutionAnalyticsViewSet;
      console.log(url);
      this.institutionAnalytics = await this.api.call(this.essentials, url);
    },

    async showDownloadsMobileApp() {
      this.showDownloads = (await this.Helper.getDevicePlatform()) != "web";
    },

    async onRefresh() {
      this.getFeaturesAnalytics();
      this.getInstitutionAnalytics();
    },
  },

  async created() {
    setTimeout(() => (this.showInstitutionAnalytics = true), 200);
    setTimeout(() => (this.showFeaturesAnalytics = true), 300);
    this.onRefresh();
    this.showDownloadsMobileApp();
  },
};
</script>

<style>
</style>